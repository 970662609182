<template>
  <div>
    <FilterView/>
    <b-card no-body class="mb-0">
      <ProductTable :products="_products"/>
    </b-card>
  </div>
</template>

<script>
import FilterView from '@/views/product/product/ProductFilter.vue'
import { mapGetters } from 'vuex'
import ProductTable from '@/views/product/product/ProductTable.vue'

export default {
  name: 'ProductHome',
  components: {
    FilterView,
    ProductTable,
  },
  computed: {
    ...mapGetters('product', ['_products']),
  },
  mounted() {
    this.$store.dispatch('product/getProductBrands')
    this.$store.dispatch('product/getProductStatus')
  },
}
</script>

<style scoped>

</style>
